import './fontawesome-free/js/all.js';
// import './headroom/headroom.js';
// import './headroom/jQuery.headroom.js';
import MatchHeight from '@tannerhodges/match-height';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit.js';
import 'lazysizes/plugins/bgset/ls.bgset.js';
// import { debounce } from 'lodash';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './global.js';

// Import our CSS
import '../scss/app.scss';
import '../css/app.pcss';

// const resize_ob = new ResizeObserver(debounce(function(entries) {
//     let rect = entries[0].contentRect;
//
//     entries[0].target.parentElement.style.height = (window.innerWidth >= 960 ? `${rect.height}px` : null)
// }), 500, false);

const main = async () => {
    document.addEventListener("DOMContentLoaded", () => {
        // const factsEl = document.querySelector("#importantFacts");
        // if (factsEl) {
        //     resize_ob.observe(factsEl);
        // }

        const countEl = document.querySelector('.dynamic-count .dynamic-count__box')
        if (countEl) {
            countEl.style.minWidth = `${countEl.offsetWidth + 30}px`
        }

        const matchHeightEls = document.querySelectorAll('.match-heights');
        if (matchHeightEls.length > 1) {
            Array.from(matchHeightEls).forEach((el) => {
                MatchHeight.add(el, 'match-height');
            });
        }
    });

    const navToggle = document.body.querySelector('.js-nav-hamburger');
    const mobileNav = document.body.querySelector('.js-nav-mobile');
    let navOpen = false;
    navToggle.addEventListener('click', () => {
        navOpen = !navOpen;
        mobileNav.style.height = `${window.innerHeight}px`;

        if (navOpen) {
            disableBodyScroll(mobileNav);
        } else {
            enableBodyScroll(mobileNav);
        }
    });

    const scrollGroups = document.querySelectorAll('.scroll-group');
    Array.from(scrollGroups).forEach((element) => {
        const scrollEl = element.querySelector('.scroll-on-hover');
        let hover = false;

        element.addEventListener("mouseenter", () => {
            hover = true
            setTimeout(() => scrollEl.style.overflowY = hover ? 'auto' : 'hidden',500);
        });

        element.addEventListener("mouseleave", () => {
            hover = false
            scrollEl.style.overflowY = 'hidden';
            setTimeout(() => scrollEl.style.overflowY = 'hidden',510);
        });
    });

    // const doubleTapTiles = document.querySelectorAll('.portfolio .tile');
    // const isHovered = e => e.parentElement.querySelector(':hover') === e;
    // for (let tile of doubleTapTiles) {
    //     const anchor = tile.closest('a');
    //
    //     if (anchor) {
    //         const siblings = Array.from(doubleTapTiles).filter((el) => el !== tile);
    //
    //         anchor.addEventListener('click', (e) => {
    //             e.preventDefault();
    //             // if (!tile.classList.contains('active') && !isHovered(tile)) {
    //             //     e.preventDefault();
    //             // }
    //             //
    //             console.log(isHovered(tile))
    //             tile.classList.add('active');
    //             siblings.forEach((el) => el.classList.remove('active'));
    //         }, true);
    //     }
    // }
};
// Execute async function
main().then(() => {
    console.log();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}
